import React from 'react'

const TextInput = ({placeholder, value, onValueChange, formatter, type = 'text', initialValue}) => {

  const handleOnChange = (e) => {
    let inputValue = e.target.value;
    if (formatter) {
        inputValue = formatter(inputValue);
    }
    onValueChange(inputValue);
  };

  return (
    
    <div className=''>
        <input value={initialValue ? initialValue : value} className='py-3 w-full px-6 rounded-xl focus:outline-orange-500' type={type} placeholder={placeholder} onChange={handleOnChange} />
    </div>
  )
}

export default TextInput