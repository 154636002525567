import React from 'react'

const Success = () => {
  return (
    <div className='bg-[#DAF2FF] h-screen text-center flex flex-col items-center justify-center '>
        <h2 className='text-4xl font-semibold'>Merci, pour votre demande d'inscription</h2>
    </div>
  )
}

export default Success