import { toast } from "react-toastify";

export const testDateDDMMAAAA = (date) => {
    const regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    const result = regex.test(date);
    if(result == false) {
        toast.error('Merci de vérifier la date renseignée.');
    }
    return regex.test(date);
}

export const testPostCode = (code) => {
    const regex = /^[0-9]{5}$/;
    const result = regex.test(code);
    if(result == false) {
        toast.error('Merci de vérifier le code postal renseigné.');
    }
    return regex.test(code);
}

export const testEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const result = regex.test(email);
    if(result == false) {
        toast.error('Merci de vérifier l\'adresse email renseignée.');
    }
    return regex.test(email);
}

export const testPhone = (phone) => {
    // regex with spaces or dashes between groups of 2 digits, optional country code
    const regex = /(\d{2}) (\d{2}) (\d{2}) (\d{2}) (\d{2})/;
    const result = regex.test(phone);
    if(result == false) {
        toast.error('Merci de vérifier le numéro de téléphone renseigné.');
    }
    return result;
}


export const testIBAN = (iban) => {
    // Supprimer les espaces pour une vérification cohérente
    const cleanIban = iban.replace(/\s+/g, '');

    // Vérifier la longueur de l'IBAN
    if (cleanIban.length < 14 || cleanIban.length > 34) {
        toast.error('Merci de vérifier l\'IBAN renseigné.');
        return false; // Longueur invalide
    }

    // Regex pour vérifier le format de base de l'IBAN
    const regexPattern = /^[A-Z]{2}[0-9]{2}[A-Z0-9]+$/;

    // Vérifier le format
    if (!regexPattern.test(cleanIban)) {
        toast.error('Merci de vérifier l\'IBAN renseigné.');
        return false; // Format invalide

    }

    // Ajouter des vérifications de la clé de contrôle si nécessaire

    return true; // L'IBAN est valide
}

