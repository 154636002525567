import React from 'react'

const RadioSelect = ({image, name, label, value, onclick, removeValue}) => {
  return (
    <label onClick={onclick}  htmlFor={label} className={`${value == name ? 'border border-orange-500 bg-orange-100  font-semibold' : 'bg-white'} text-sm text-center ${image == 'h-0' ? '':'h-24'}  py-3 px-5 rounded-lg  w-full mobile:w-full flex flex-col items-center justify-around`}>
          {image && <img src={image} alt="" />}
          <p>{label}</p>
    </label>
  )
}

export default RadioSelect