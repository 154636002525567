import React from 'react'

const Error = () => {
  return (
    <div className='bg-[#DAF2FF] h-screen text-center flex flex-col items-center justify-center '>
        <h2 className='text-4xl font-semibold'>Malheureusement, vous ne pouvez pas souscrire en ligne à nos offres</h2>
        <p className='py-24 text-xl'>En effet, après avoir évaluer vos informations avec précision, nous ne sommes pas en mesure de vous fournir une souscription en ligne.</p>
    </div>
  )
}

export default Error