import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing';
import { Route, Routes } from 'react-router-dom';
import GenerateDevis from './pages/GenerateDevis';
import { ToastContainer, toast } from 'react-toastify';

  import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useLayoutEffect } from 'react';
import Error from './pages/Error';
import Success from './pages/Success';

function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/obtenir-devis-en-ligne" element={<GenerateDevis />} />
        <Route path='error' element={<Error/>} />
        <Route path='/success' element={<Success/>} />
      </Routes>
    </div>
  );
}

export default App;
