export const formatFrenchPhoneNumber = (phoneNumber) => {
    // Supprime tous les caractères non numériques du numéro de téléphone et max 10 chiffres
    const cleanPhoneNumber = phoneNumber.replace(/[^0-9]/g, '').slice(0, 10);
    // const cleanPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');

    // Formate le numéro de téléphone en ayant 10 chiffres max sans separer les chiffres
    const formattedPhoneNumber = cleanPhoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');

    // const formattedPhoneNumber = cleanPhoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1$2$3$4$5');

    return formattedPhoneNumber;
};

export const formatDateToDDMMYYYY = (date) => {
    
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');

    return `${day}/${month}/${year}`;
}

export const toPrice = (price) => {
    var price = parseFloat(price).toFixed(2);
    // replace point price by comma with euro
    return `${price.replace('.', ',')} €`;
}

export const ibanFormatter = (iban) => {
      if(iban.length === 0) return '';

      // Supprime tous les caractères non numériques de l'IBAN
      const cleanIban = iban.replace(/[^0-9a-zA-Z]/g, '');

      // Tronque l'IBAN à la longueur maximale spécifiée
      const truncatedIban = cleanIban.slice(0, 27);
  
      // Formate l'IBAN en groupes de quatre caractères séparés par un espace
      let formattedIban = truncatedIban.match(/.{1,4}/g).join(' ');
  
      return formattedIban;
}

export const dateWithSlashFormatter = (date) => {
    // Expression régulière ajustée pour accepter uniquement les jours de 01 à 31, les mois de 01 à 12, et les années plausible (exemple 1900 à 2099)
    return date.replace(/(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(19\d{2}|20\d{2})/, '$1/$2/$3').slice(0,10);
};


export const postcodeFormatter = (postcode)  => {
    // Supprime tous les caractères non numériques du code postal
    const cleanPostcode = postcode.replace(/[^0-9]/g, '').slice(0, 5);
    return cleanPostcode;
}